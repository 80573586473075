import React, { useState, useContext } from "react";
import {
  ModalAxon,
  FormContainer,
  FormField,
} from "@axontraining-sistemas/lib-common";
import { FaEdit } from "react-icons/fa";
import ActionButton from "../../Common/ActionButton/ActionButton";
import { DatoContext } from "../../Context/datoContext";

import { UtilDataContext } from "../../Context/utilDataContext";
import { editValidation } from "./editValidation";
import { mainMargin } from "../../Services/UltilsService";
import { Box, SimpleGrid } from "@chakra-ui/layout";
import { putDato, validateEmail } from "../../Services/EditDataService";
import usePermisos from "../../Hooks/usePermisos";
import { SEARCH_ADMIN } from "../../Constants/buscarDato";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {SessionContext} from "../../Context/sessionContext";

const EditarDato = ({ data, editResult, isDisabled }) => {
  const { onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { pushNewDato, fetchPrevDatos } = useContext(DatoContext);
  const { paises, getAllServices, filterEstadosVentaVendedor, operadores } =
    useContext(UtilDataContext);

  const { session } = useContext(SessionContext);

  const userPermissions = {
    '178262': ['edit_service'],
    '55660': ['edit_service'],
    '1513': ['edit_service']
  };

  const [formValues, setFormValues] = useState({});

  const { hasPermiso } = usePermisos();
  const isAdmin = hasPermiso(SEARCH_ADMIN);

  const handleSubmit = async (onClose, updateEmail = false) => {
    setIsLoading(true);

    try {
      await validateEmail(formValues, data.idVenta, updateEmail);
    } catch (error) {
      if (!error.showToast) setShowUpdateEmail(true);
      else editResult(400, { ...data, ...formValues }, error.error);
      setIsLoading(false);
      setErrorMessage(error.error);
      return;
    }

    let res;
    try {
      res = await putDato(formValues, data.idVenta);
    } catch (error) {
      editResult(400, { ...data, ...formValues }, error);
      setIsLoading(false);
      return onClose();
    }

    fetchPrevDatos();
    // pushNewDato(data.idVenta, data.fecha);

    editResult(res.status, { ...data, ...formValues });

    setIsLoading(false);

    return onClose();
  };

  const updateDato = async () => {
    setShowUpdateEmail(false);
    await handleSubmit(onClose, true);
  };

  const handleChange = ({ values }) => setFormValues(values);

  return (
    <>
      <ModalAxon
        isDisabled={isDisabled}
        title={`Editar Dato (ID Venta ${data.idVenta})`}
        size="4xl"
        trigger={
          <ActionButton
            iconComponent={
              <FaEdit size={18} color={`${isDisabled ? "gray" : "206cc6"}`} />
            }
          />
        }
        render={({ onClose, isOpen }) => (
          <FormContainer
            handleSubmit={() => handleSubmit(onClose)}
            validationSchema={editValidation}
            initialValues={{
              nombre: data.nombre ?? "",
              apellido: data.apellido ?? "",
              idPais: data.pais?.id ?? "",
              celular: data.celular ?? "",
              idServicio: data.idServicio ?? "",
              email: data.email ?? "",
              estadoVenta: data.idEstadoVenta ?? "",
              observaciones: data.observaciones ?? "",
              responsable: data.responsable ?? "",
            }}
            handleChange={handleChange}
          >
            {isOpen && (
              <>
                <SimpleGrid columns={2} spacing={mainMargin}>
                  <FormField
                    bgInput="white"
                    name="nombre"
                    label="Nombre"
                    placeholder="Ingrese nombre"
                    type="input"
                    labelColor="gray.500"
                  />

                  <FormField
                    bgInput="white"
                    name="apellido"
                    label="Apellido"
                    placeholder="Ingrese apellido"
                    type="input"
                    labelColor="gray.500"
                  />

                  <FormField
                    isClearable={false}
                    name="estadoVenta"
                    label="Estado"
                    placeholder="Seleccione estado"
                    type="react-select"
                    options={filterEstadosVentaVendedor()}
                    labelColor="gray.500"
                  />

                  {userPermissions[session?.id]?.includes('edit_service') && (
                    <FormField
                      name="idServicio"
                      label="Curso"
                      type="react-select"
                      options={getAllServices(true)}
                      labelColor="gray.500"
                    />)
                  }

                  <FormField
                    name="idPais"
                    label="País"
                    placeholder="Seleccione pais"
                    type="react-select"
                    options={paises}
                    labelColor="gray.500"
                  />

                  <FormField
                    bgInput="white"
                    name="celular"
                    label="Celular"
                    placeholder="Ingrese celular"
                    type="input"
                    labelColor="gray.500"
                  />
                  <FormField
                    bgInput="white"
                    name="email"
                    label="Email"
                    placeholder="Ingrese email"
                    type="input"
                    labelColor="gray.500"
                  />
                  <FormField
                    bgInput="white"
                    name="observaciones"
                    label="Observaciones"
                    placeholder="Ingrese observaciones"
                    type="input"
                    labelColor="gray.500"
                  />
                  {isAdmin && (
                    <FormField
                      name="responsable"
                      label="Operador"
                      placeholder="Seleccione operador"
                      type="react-select"
                      options={operadores}
                      labelColor="gray.500"
                    />
                  )}
                </SimpleGrid>

                <Box mt={mainMargin + 30} float="right" mb={10}>
                  <FormField
                    name="submit"
                    type="submit"
                    placeholder="Guardar"
                    bgInput="blue.500"
                    colorScheme="blue.500"
                    labelColor="gray.500"
                    size="sm"
                    isLoading={isLoading}
                  />
                </Box>
              </>
            )}
          </FormContainer>
        )}
      />
      <Modal
        isOpen={showUpdateEmail}
        onClose={() => setShowUpdateEmail(!showUpdateEmail)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email existente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>{errorMessage} ¿Está seguro que desea editarlo?</p>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setShowUpdateEmail(!showUpdateEmail)}
            >
              Cerrar
            </Button>
            <Button variant="ghost" onClick={updateDato}>
              Actualizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarDato;
