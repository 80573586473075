import axios from "axios";
import moment from "moment";

// CONSTANTES
export const mainMargin = 3;
export const today = moment().format("Y-MM-DD");

export const firstDayOfTheMonth = moment().startOf("month").format("Y-MM-DD");
export const aWeekAgo = moment().add(-7, "days").format("Y-MM-DD");
export const TwoWeekAgo = moment().add(-15, "days").format("Y-MM-DD");

// FUNCTIONS
export const fechaAmigable = (fecha) => {
  return moment(fecha, "YYYY-MM-DD").format("DD-MM-YYYY");
};

export const fechaCorta = (fecha) => {
  return moment(fecha, "DD-MM-YYYY").format("DD-MM");
};

export const importeAmigable = (importe) => {
  let importeFormateado = parseFloat(importe);

  if (importe > 10000 || importe < -10000) {
    importeFormateado = Math.round(importeFormateado / 1000);
    importeFormateado += "K";
  } else {
    importeFormateado = Math.round(importeFormateado);
  }

  return importeFormateado;
};

export const clearData = (object) => {
  for (const key in object) {
    if (object[key] == "" || !object[key]) delete object[key];
  }
  return object;
};

export const parseArrayToReactSelect = (datos, value, label) => {
  const options = datos.map((dato) => {
    return { label: dato[label], value: dato[value] };
  });
  return options;
};

export const getBaseUrl = () => {
  return window.location.hostname === "crm.axontraining.com"
    ? "https://api.axontraining.com"
    : "https://api-dev.axontraining.com";
};

export const generateLog = async (table, params) => {
  const url = "https://api.axontraining.com/master/logs";

  try {
    return await axios.post(
      url,
      {
        table,
        ...params,
      },
      {}
    );
  } catch (e) {
    return e;
  }
};

export const getEnvironmentValue = async (entorno, nombre) => {
  try {
    return await axios.get(`https://api.axontraining.com/internos/enviroment/${entorno}?nombre=${nombre}`)
  } catch (error) {
    return error;
  }
}